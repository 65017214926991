import React from 'react';

import { DashboardLayout } from 'layouts/DashboardLayout';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { BUTTON_VARIANTS } from 'components/Button/constants';

const Support = () => {
  const onConnectSupport = () => {
    window.open('https://t.me/suppport_rl', '_blank');
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center mx-auto justify-center w-[460px] sm:w-full pr-[280px] sm:pr-0">
        <Typography className="text-white" text="Support" variant={TYPOGRAPHY_VARIANTS.HEADER_H2} />
        <Typography
          className="text-white opacity-[0.3] mt-3 w-[370px] text-center"
          text="You can write to us in telegram. We will resolve any issue as soon as possible."
          variant={TYPOGRAPHY_VARIANTS.BODY_M}
        />
        <Button
          onClick={onConnectSupport}
          className="mt-[48px] w-[236px] h-[58px]"
          text="Contact"
          variant={BUTTON_VARIANTS.LARGE}
        />
      </div>
    </DashboardLayout>
  );
};

export default Support;
