export const MENU_LIST = {
  menu: [
    {
      icon: '/icons/circle-plus.svg',
      title: 'Create token',
      link: '/createToken'
    },
    {
      icon: '/icons/list-apply.svg',
      title: 'Create liquidity pool',
      link: '/createLiquidityPool'
    },
    {
      icon: '/icons/menu.svg',
      title: 'Dashboard',
      link: '/dashboard'
    },
    {
      icon: '/icons/market.svg',
      title: 'Market',
      link: '/market'
    }
  ],
  other: [
    {
      icon: '/icons/support.svg',
      title: 'Support',
      link: '/support'
    },
    {
      icon: '/icons/faq.svg',
      title: 'FAQ',
      link: '/faq'
    },
    {
      icon: '/icons/logout.svg',
      title: 'Log out',
      link: '/logout'
    }
  ]
};
