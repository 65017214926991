import React from 'react';

import TableHeader from './TableHeader';
import TableRow from './TableRow';
import Button from 'components/Button';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import TableRowWithoutLiquidity from './TableRowWithoutLiquidity';

const DashboardTable = ({ data, onLoadMore, isShowLoadingButton }) => {
  return (
    <React.Fragment>
      <TableHeader />
      {!data?.result?.length ? (
        <div className="flex mt-[120px] ml-auto mr-auto items-center justify-center flex-col w-[460px] sm:w-full">
          <img className="w-[48px] h-[48px]" src="/smileIcon.png" alt="smile Icon" />
          <Typography
            className="text-white opacity-[0.5] w-[78%] text-center mt-3"
            text="The tokens that you have created will be displayed here."
            variant={TYPOGRAPHY_VARIANTS.BODY_M}
          />
        </div>
      ) : (
        <React.Fragment>
          {data?.result?.map((item, index) => {
            const isWithoutLiquidity = item.price === 0;

            if (isWithoutLiquidity) {
              return <TableRowWithoutLiquidity key={index} {...item} />;
            }

            return <TableRow key={index} {...item} />;
          })}
          {isShowLoadingButton && (
            <Button
              className="mt-3"
              variant={BUTTON_VARIANTS.TABLE_MORE}
              onClick={onLoadMore}
              text="More"
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DashboardTable;
