import React, { useState } from 'react';

import { DashboardLayout } from 'layouts/DashboardLayout';
import DeployToken from 'components/CreateToken/DeployToken';
import AddLiquidity from 'components/CreateToken/AddLiquidity';
import FinalStep from 'components/CreateToken/FinalStep';
import StepByStep from 'components/CreateToken/StepByStep';

import useCreateToken from 'helpers/hooks/useCreateToken';
import useMetadataUploader from 'helpers/hooks/useMetadataUploader';
import useAddLiquidity from 'helpers/hooks/useAddLiquidity';

const CreateToken = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const [tokenImage, setTokenImage] = useState(null);

  const { deploy: createToken, isDeploying: isCreating } = useCreateToken();
  const { deploy: addLiquidity, isDeploying: isAdding } = useAddLiquidity();
  const { uploadMetadata, isUploading } = useMetadataUploader();

  const onCreateToken = async (params) => {
    const tokenMetadataURI = await uploadMetadata(params);
    if (tokenMetadataURI !== null) {
      params.tokenMetadataURI = tokenMetadataURI;
      await createToken(params, (createdTokenData) => {
        setData({
          tokenMint: createdTokenData.tokenMint,
          tokenName: params.tokenName,
          tokenSymbol: params.tokenSymbol,
          initialSupply: params.initialSupply
        });
        setStep(2);
      });
    }
  };

  const onAddLiquidity = async (params) => {
    const addLiquidityParams = { ...data, ...params };
    await addLiquidity(addLiquidityParams, () => {
      setStep(3);
    });
  };

  return (
    <DashboardLayout>
      {step === 1 && (
        <DeployToken
          setFinalTokenImage={setTokenImage}
          onDeploy={onCreateToken}
          isDeploying={isCreating || isUploading}
        />
      )}
      {step === 2 && (
        <AddLiquidity
          initialSupply={data?.initialSupply}
          onDeploy={onAddLiquidity}
          isDeploying={isAdding}
        />
      )}
      {step === 3 && (
        <FinalStep
          tokenImage={tokenImage}
          setStep={setStep}
          tokenAddress={data.tokenMint?.toString()}
        />
      )}
      {(step === 1 || step === 2) && <StepByStep step={step} />}
    </DashboardLayout>
  );
};

export default CreateToken;
