import React, { useRef } from 'react';

import { useWallet } from 'contexts/wallet';
import ReactPortal from 'components/Portal';
import Typography from 'components/Typography';
import { getWalletsForConnect } from './constants';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { useClickOutside } from 'helpers/hooks/useClickOutside';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';

const ConnectWalletModal = ({ isOpened, setOpened }) => {
  const { connectWallet, disconnectWallet } = useWallet();
  const { resetBlurBackground } = useBlurBackground();
  const ref = useRef(null);

  const onCloseWalletModal = () => {
    resetBlurBackground();
    setOpened(false);
  };

  useClickOutside(ref, () => {
    onCloseWalletModal();
  });

  return (
    <ReactPortal isOpen={isOpened}>
      <div
        ref={ref}
        className="flex w-[600px] modal text-white fixed top-0 sm:top-auto sm:bottom-0 pt-[36px] left-0 z-[999999]">
        <div className="w-full flex justify-center relative py-[50px] overflow-auto  bg-black-1000 px-[60px] pt-[80px] pb-[48px] rounded-[48px]">
          <div
            onClick={onCloseWalletModal}
            className="cursor-pointer absolute top-[30px] right-[30px] sm:top-[16px] sm:right-[130px] bg-white-100 flex items-center justify-center rounded-full w-[44px] h-[44px]">
            <img className="w-[24px] h-[24px]" src="/closeIcon.svg" alt="close icon" />
          </div>

          <div className="flex flex-col sm:h-full">
            <div className="flex flex-col items-center space-y-6 max-w-[460px] sm:max-w-full w-full">
              <div className="flex flex-col items-center justify-center space-y-3 sm:space-y-1">
                <Typography
                  className="text-white"
                  text="Connect your wallet"
                  variant={TYPOGRAPHY_VARIANTS.BODY_H2}
                />
                <Typography
                  className="text-white-500 sm:max-w-[200px] text-center"
                  text="Select your favourite wallet to log in Rocket Launcher."
                  variant={TYPOGRAPHY_VARIANTS.BODY_S}
                />
              </div>
              <div className="flex flex-col space-y-3 max-w-[460px] sm:max-w-full w-full">
                {getWalletsForConnect(connectWallet, disconnectWallet).map((item, itemIndex) => {
                  return (
                    <button
                      onClick={() => item?.func()}
                      className={`flex items-center justify-between p-2 border border-white-100 rounded-[100px] ${item?.isEnabled ? '' : 'opacity-50'}`}
                      disabled={!item?.isEnabled}
                      key={itemIndex}>
                      <div className="flex items-center justify-center space-x-4">
                        <img
                          className="rounded-full w-[60px] h-[60px] sm:w-[44px] sm:h-[44px]"
                          src={item?.image}
                          alt="wallet image"
                        />
                        <div className="flex flex-col justify-center items-start">
                          <Typography
                            className="text-white"
                            text={item.title}
                            variant={TYPOGRAPHY_VARIANTS.HEADER_H4}
                          />
                          <Typography
                            className="text-white-500 mt-2"
                            text={item.desc}
                            variant={TYPOGRAPHY_VARIANTS.BODY_M}
                          />
                        </div>
                      </div>
                      {item?.isEnabled && !item?.isNeedDownload && (
                        <div className="h-[60px] sm:h-[44px] min-w-[80px] rounded-[166px] flex items-center justify-center border border-green-200 bg-green-50">
                          <Typography
                            className="text-light-green"
                            text={item?.isConnected ? 'Disconnect' : 'Connect'}
                            variant={TYPOGRAPHY_VARIANTS.XS}
                          />
                        </div>
                      )}
                      {item?.isEnabled && item?.isNeedDownload && (
                        <a href={item.downloadUrl} target="_blank" className="" rel="noreferrer">
                          <div className="h-[60px] sm:h-[44px] min-w-[80px] rounded-[166px] text-orange text-[12px] leading-[14px] text-light flex items-center justify-center border border-orange-200 bg-orange-50">
                            {'Download'}
                          </div>
                        </a>
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default ConnectWalletModal;
