import React from 'react';
import clsx from 'clsx';
import { TYPOGRAPHY_VARIANTS } from '../Typography/constants';
import Typography from '../Typography';

const Radio = ({ text, className, onClick, title, options = [], value, ...props }) => {
  const onClickHandler = (event, item) => {
    event.preventDefault();
    onClick(item);
  };

  return (
    <div
      className={clsx(`flex flex-col items-start justify-center space-y-[12px] w-full`, className)}>
      <Typography
        className="text-white pl-[24px]"
        text={title}
        variant={TYPOGRAPHY_VARIANTS.HEADER_H4}
      />
      <div className="flex space-x-3 sm:w-full">
        {options.map((item) => {
          const isActive = item === value;
          return (
            <button
              onClick={(event) => onClickHandler(event, item)}
              key={item}
              className="rounded-[100px] bg-white-10 px-6 py-5 w-[224px] sm:w-full border border-white-100 flex items-center justify-center"
              {...props}>
              {isActive ? (
                <img src="/icons/radioButtonActive.svg" alt="radio button active icon" />
              ) : (
                <img src="/icons/radioButtonDisabled.svg" alt="radio button disabled icon" />
              )}
              <span className={`${!isActive && 'opacity-[0.5]'} text-white ml-2`}>{item}%</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Radio;
