import React, { useContext, createContext } from 'react';
import { useSnackbar } from 'notistack';
import { Connection } from '@solana/web3.js';

import { SOLANA_ENDPOINT } from 'config';
const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const showInfoNotification = (title, message) => {
    return enqueueSnackbar(
      { type: 'info', title, message },
      {
        autoHideDuration: 3000,
        persist: false
      }
    );
  };

  const showTxNotification = (description, hash, status, result) => {
    return enqueueSnackbar(
      { type: 'tx', description, hash, status, result },
      {
        autoHideDuration: 3000,
        persist: false
      }
    );
  };

  const showErrorNotification = (msg) => {
    return enqueueSnackbar(
      {
        type: 'error',
        message: msg?.error?.message || msg.responseText || msg.message || msg
      },
      {
        autoHideDuration: 3000,
        persist: false
      }
    );
  };

  const showSuccessNotification = (title, message) => {
    return enqueueSnackbar(
      {
        type: 'success',
        title,
        message
      },
      {
        autoHideDuration: 3000,
        persist: false
      }
    );
  };

  const tx = async (startNotification, endNotification, makeTx) => {
    try {
      const result = await makeTx();
      const signatures = result.signatures ?? [result.signature];
      const connection = new Connection(SOLANA_ENDPOINT, 'confirmed');

      const startTime = Date.now();
      const timeout = 60000; // 1 minute in milliseconds
      let confirmedSignatures = new Set();
      while (confirmedSignatures.size < signatures.length) {
        if (Date.now() - startTime > timeout) {
          throw new Error('Timeout reached. Try again');
        }
        const statuses = await connection.getSignatureStatuses(signatures);
        const results = statuses && statuses.value;
        results.forEach((status, index) => {
          const signature = signatures[index].toString();
          if (!status || confirmedSignatures.has(signature)) {
            return; // already confirmed
          }
          const { confirmationStatus, err } = status;
          if (confirmationStatus != 'confirmed' && confirmationStatus != 'finalized') {
            return; // not confirmed yet
          }
          if (err) {
            if (err.InsufficientFundsForRent) {
              throw new Error('Transaction failed: insufficient funds');
            }
            if (err.InstructionError) {
              const [_, errorDetails] = err.InstructionError;
              if (errorDetails.Custom === 1) {
                throw new Error('Transaction failed: insufficient funds');
              }
            }
            throw new Error('Transaction failed');
          }
          confirmedSignatures.add(signature);
          showTxNotification(endNotification, signature, 'success');
        });
        if (confirmedSignatures.size < signatures.length) {
          await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds
        }
      }
    } catch (e) {
      showErrorNotification(e.reason || e.message);
      throw e;
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        showTxNotification,
        showErrorNotification,
        showSuccessNotification,
        showInfoNotification,
        tx
      }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotifications() {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('Missing Notifications context');
  }
  const {
    showTxNotification,
    showErrorNotification,
    showSuccessNotification,
    showInfoNotification,
    tx
  } = context;
  return {
    showTxNotification,
    showErrorNotification,
    showSuccessNotification,
    showInfoNotification,
    tx
  };
}
