import React, { useState } from 'react';

import HeaderMain from 'components/Headers/Main';
import Footer from 'components/Footer';
import Typography from 'components/Typography';
import MainTag from 'components/Tags/MainTag';
import Button from 'components/Button';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import ConnectWalletModal from 'components/Modals/ConnectWalletModal';
import MainTableController from 'controllers/MainTableController';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';
import { WhyService } from 'features/Main/WhyService';
import { Roadmap } from 'features/Main/Roadmap';

const Main = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { setBlurBackground } = useBlurBackground();

  const onConnectWallet = () => {
    setBlurBackground();
    setIsOpened(true);
  };

  return (
    <React.Fragment>
      <HeaderMain />
      <div className="flex mt-[192px] mb-[120px] sm:mt-[158px] flex-col items-center justify-center bg-black-1000">
        <div className="flex flex-col items-center justify-center w-full min-h-screen pt-[36px]">
          <MainTag text="Fast. Cheap. Secure." />
          <div className="relative mt-4 sm:mt-3 text-center text-white text-[60px] leading-[65px] sm:text-[32px] sm:leading-[36px] font-semibold max-w-[686px] w-full mb-[12px] sm:mb-2">
            <Typography
              className="relative z-10"
              text="Own the Future"
              variant={TYPOGRAPHY_VARIANTS.HEADER_H1}
            />
            <br />
            <Typography
              className="relative z-10"
              text="with Rocket Launcher"
              variant={TYPOGRAPHY_VARIANTS.HEADER_H1}
            />
            <img
              className="z-[0] absolute top-[-14px] right-[155px] sm:w-[64px] sm:right-[78px] sm:top-[-14px]"
              src="/orangeShadow.png"
              alt="background image"
            />
          </div>
          <Typography
            className="text-center opacity-50 text-white-1000 max-w-[750px] sm:max-w-[90%] w-full"
            text="Launch your memecoin on Solana - instantly tradeable on Raydium. No&nbsp;liquidity for the pool and no coding skills required. Choose any market cap you like. Find&nbsp;your token on dextools, birdeye and geckoterminal"
            variant={TYPOGRAPHY_VARIANTS.BODY_L}
          />
          <Button
            onClick={onConnectWallet}
            className="mt-9 sm:mt-6"
            variant={BUTTON_VARIANTS.LARGE}
            text="Connect Wallet"
          />
          <div className="overflow-auto mt-20 sm:mt-[60px] max-w-[1050px] sm:max-w-full w-full min-h-[492px]">
            <MainTableController />
          </div>
        </div>
      </div>
      <WhyService wrapperClass="mb-[160px]" />
      <Roadmap />
      <Footer />
      {isOpened && <ConnectWalletModal isOpened={isOpened} setOpened={setIsOpened} />}
    </React.Fragment>
  );
};

export default Main;
