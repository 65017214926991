import React from 'react';
import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// import required modules
import { Autoplay } from 'swiper/modules';

import { Item } from './Item';

export const WhyService = ({ wrapperClass = '' }) => {
  const list = [
    {
      title: 'Cheapest and Most Efficient',
      desc: 'Launching your coin with Rocket Launcher you are choosing the most efficient option possible. Our smart contracts are heavily optimised to make the launch process revolutionary cheap - the cheapest you can find so far. You won`t have to wait for your coin to achieve a certain trading volume and can enjoy the direct listing on Raydium right away.',
      img: '/whyService/logo.webp'
    },
    {
      title: 'Solana Bundler for pump.fun',
      desc: 'Easy to use, no programming skills needed and pretty affordable. You can buy your token ahead of everyone from multiple accounts in few buttons.',
      img: '/whyService/menu.webp'
    },
    {
      title: 'Liquidity Pools v3',
      desc: 'We use liquidity pools V3. That means you won`t have to deposit any real funds when creating a pool via Rocket Launcher. You don`t have to risk your funds and you are safe from rugpuls.',
      img: '/whyService/v3.webp'
    },
    {
      title: 'Easy and Fun',
      desc: 'You don`t have to be a developer or have special tech skills to launch a coin with Rocket Launcher. Our smart contracts do the heavy lifting for you. Enjoy the user-friendly interface with low costs and incredible speed.',
      img: '/whyService/createTokens.webp'
    },
    {
      title: 'Support at Hand',
      desc: 'If something is wrong or you are not sure what to do next we are always here for you. Ping us on X, Telegram or Discord. Don`t forget to follow us there so as not to miss any updates and to stay in touch. See you on the moon!',
      img: '/whyService/socials.webp'
    }
  ];

  return (
    <div className={`flex flex-col items-center space-y-[60px] ${wrapperClass}`}>
      <div className="flex flex-col items-center text-center space-y-3">
        <Typography
          className="text-white"
          variant={TYPOGRAPHY_VARIANTS.HEADER_H2}
          text="Why Rocket Launcher"
        />
        <Typography
          className="text-white-500 max-w-[725px] w-full"
          variant={TYPOGRAPHY_VARIANTS.BODY_L}
          text="You have surely thought something like it takes too much effort to get into this. Because other projects don`t have it as cheap and safe as we do."
        />
      </div>
      <div className="">
        <Swiper
          slidesPerView={3}
          spaceBetween={12}
          loop={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false
          }}
          modules={[Autoplay]}
          className="text-white max-w-[1112px]">
          {list.map((item, itemIndex) => {
            return (
              <SwiperSlide style={{ height: 'unset' }}>
                <Item {...item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
