import React, { useEffect, useState } from 'react';
import JSBI from 'jsbi';

import { useWallet } from 'contexts/wallet';
import { ammConfig10000 } from 'utils/amm-configs';
import { MIN_MARKET_CAP, MAX_MARKET_CAP } from './constants';

import Button from 'components/Button';
import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import NumberInput from '../Inputs/NumberInput';
import Radio from 'components/Radio';

const AddLiquidity = ({ onDeploy, isDeploying, initialSupply }) => {
  const [formData, setFormData] = useState({
    initialSupply: initialSupply?.toString() || '',
    marketCap: '',
    poolConfig: ammConfig10000,
    liquidityPoolPercent: 100
  });
  const [isValid, setIsValid] = useState(false);
  const { userAddress } = useWallet();

  const handleChangeRadio = (value) => {
    setFormData({
      ...formData,
      liquidityPoolPercent: value
    });
  };

  useEffect(() => {
    const validateForm = () => {
      if (Number(formData.initialSupply) === 0) return false;
      return Number(formData.marketCap) !== 0;
    };
    setIsValid(validateForm());
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (formData[name] === value) return;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDeploy = (e) => {
    e.preventDefault();
    onDeploy({
      initialSupply: JSBI.BigInt(formData.initialSupply),
      poolConfig: formData.poolConfig,
      marketCap: JSBI.BigInt(formData.marketCap),
      liquidityPoolPercent: formData.liquidityPoolPercent
    });
  };

  const isDisabled = !isValid || !userAddress || isDeploying;

  return (
    <div className="flex flex-col mx-auto sm:mx-0 overflow-auto pr-[280px] sm:pr-0">
      <div className="flex flex-col items-center justify-center space-y-[12px] max-w-[460px] sm:max-w-full mt-[48px] sm:mt-[20px]">
        <Typography
          className="text-white"
          text="Starting market cap"
          variant={TYPOGRAPHY_VARIANTS.HEADER_H2}
        />
        <Typography
          className="text-white text-center w-[420px] sm:w-[280px] opacity-[0.5]"
          text="The starting market cap affects the initial token price and its level of volatility"
          variant={TYPOGRAPHY_VARIANTS.BODY_M}
        />
      </div>

      <div className="w-full max-w-[460px] sm:max-w-full py-[48px]">
        <form className="w-full flex flex-col items-center space-y-[32px]" onSubmit={handleDeploy}>
          <div className="flex flex-col relative items-start justify-center space-y-[12px] w-full">
            <NumberInput
              className="relative w-full"
              title="Market cap size in usd"
              id="marketCap"
              min="10000"
              max="10000000"
              value={formData.marketCap}
              placeholder="Example, 20 000"
              handleChange={(e) => handleChange(e)}
            />
            <span className="absolute top-[36px] sm:top-[30px] right-12 text-white-500">$</span>
            {!!formData.marketCap &&
              (Number(formData.marketCap) < MIN_MARKET_CAP ||
                Number(formData.marketCap) > MAX_MARKET_CAP) && (
                <Typography
                  className="pl-[24px] mt-3 text-red"
                  text="The value must be between 10,000 and 10,000,000"
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              )}
          </div>
          <div className="flex flex-col relative items-start justify-center space-y-[12px] w-full">
            <Radio
              onClick={handleChangeRadio}
              value={formData.liquidityPoolPercent}
              title="Send to Liquidity Pool"
              options={[100, 95]}
            />
            <Typography
              className="pl-[24px] mt-3 text-white opacity-[0.5]"
              text={`${formData.liquidityPoolPercent}% tokens will go in Liquidity Pool.`}
              variant={TYPOGRAPHY_VARIANTS.CAPTION}
            />
          </div>
          <Button
            isDisabled={isDisabled}
            text="Deploy"
            isLoading={isDeploying}
            type="submit"
            className="w-[460px] h-[58px] sm:w-full"
            variant={BUTTON_VARIANTS.LARGE}
          />
          <Typography
            className="w-[236px] text-center text-white opacity-[0.5]"
            text="Click on DEPLOY, accept the transactions and wait until your tokens ready."
            variant={TYPOGRAPHY_VARIANTS.CAPTION}
          />
        </form>
      </div>
    </div>
  );
};

export default AddLiquidity;
