import React, { useState, useRef, useMemo } from 'react';
import JSBI from 'jsbi';

import { useWallet } from 'contexts/wallet';
import Typography from 'components/Typography';
import TextInput from 'components/Inputs/TextInput';
import TextAreaInput from 'components/Inputs/TextAreaInput';
import NumberInput from 'components/Inputs/NumberInput';
import Button from 'components/Button';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import { MAX_IMAGE_SIZE } from './constants';

const DeployToken = ({ setFinalTokenImage, onDeploy, isDeploying }) => {
  const [formData, setFormData] = useState({
    tokenName: '',
    tokenSymbol: '',
    tokenDescription: '',
    initialSupply: ''
  });
  const [tokenImage, setTokenImage] = useState(null);

  const ref = useRef();
  const { userAddress } = useWallet();

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setTokenImage(file);
    } else {
      setTokenImage(null);
    }
    event.target.value = null;
  };

  const isValidForm =
    formData.tokenName.length !== 0 &&
    formData.tokenSymbol.length !== 0 &&
    Number(formData.initialSupply) !== 0 &&
    tokenImage !== null;

  const handleChange = (e) => {
    const name = e.target?.name;
    const value = e.target?.value;
    if (formData[name] === value) return;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChangeText = (e) => {
    const { name, value } = e.target;

    if (formData[name] === value) return;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDeploy = (e) => {
    e.preventDefault();
    setFinalTokenImage(tokenImage);
    // Call onDeploy:
    onDeploy({
      tokenName: formData.tokenName,
      tokenSymbol: formData.tokenSymbol,
      tokenDescription: formData.tokenDescription,
      initialSupply: JSBI.BigInt(formData.initialSupply),
      imageFile: tokenImage
    });
  };

  const avatarBg = useMemo(() => {
    return tokenImage
      ? {
          backgroundImage: `url(${typeof tokenImage === 'string' ? tokenImage : URL.createObjectURL(tokenImage)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      : {};
  }, [tokenImage]);

  const isDisabled = !isValidForm || !userAddress || isDeploying;

  return (
    <div className="flex flex-col mx-auto overflow-auto pr-[280px] sm:pr-0">
      <div className="flex flex-col items-center justify-center w-[460px] sm:w-full">
        <Typography
          className="text-white"
          text="Create token"
          variant={TYPOGRAPHY_VARIANTS.HEADER_H2}
        />
        <Typography
          className="text-white text-center opacity-[0.5] mt-[12px]"
          text="Customize your Solana Token exactly the way you envision it. Less than 5 minutes, at an
          affordable cost."
          variant={TYPOGRAPHY_VARIANTS.BODY_M}
        />
      </div>

      <div className="w-full max-w-[460px] sm:max-w-full py-[48px] sm:py-[36px]">
        <form className="w-full flex flex-col items-center space-y-[32px]" onSubmit={handleDeploy}>
          <div className="relative flex flex-col items-center justify-center">
            {!!tokenImage && (
              <div
                onClick={() => setTokenImage(null)}
                className="hover:opacity-75 z-[3] cursor-pointer rounded-full absolute top-0 right-0 bg-red w-[24px] h-[24px] flex flex-col items-center justify-center">
                <img
                  className="w-[16px] h-[16px]"
                  src="/closeIcon.svg"
                  alt="remove token image icon"
                />
              </div>
            )}
            <input
              ref={ref}
              accept="image/png, image/jpeg"
              className="hidden"
              id="contained-button-file"
              type="file"
              onChange={handleImageChange}
              maxsize={MAX_IMAGE_SIZE}
            />

            <button
              type="button"
              onClick={() => ref.current.click()}
              className="rounded-full flex items-center justify-center logo-btn-gradient h-[100px] w-[100px]">
              {tokenImage ? (
                <div
                  className="hover:opacity-90 rounded-full w-[98%] h-[98%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  style={avatarBg}></div>
              ) : (
                <div className="hover:opacity-90 rounded-full bg-black-1000 flex items-center justify-center w-[98%] h-[98%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <img className="opacity-30" src="/imageLoader.svg" alt="image loader" />
                </div>
              )}
            </button>
          </div>
          <TextInput
            title="Name"
            maxLength="32"
            id="tokenName"
            value={formData.tokenName}
            handleChange={handleChange}
            placeholder="Specify the desired name for your Token"
          />
          <TextInput
            title="Symbol"
            maxLength="10"
            id="tokenSymbol"
            value={formData.tokenSymbol}
            handleChange={handleChange}
            placeholder="Indicate the symbol (max 10 characters)"
          />
          <TextAreaInput
            title="Description"
            id="tokenDescription"
            value={formData.tokenDescription}
            handleChange={(e) => handleChangeText(e)}
            placeholder="Provide a brief description for your SPL Token"
          />
          <React.Fragment>
            <NumberInput
              title="Total Supply"
              id="initialSupply"
              value={formData.initialSupply}
              placeholder="Determine the Supply of your Token"
              handleChange={(e) => handleChange(e)}
            />
            {!!formData.initialSupply &&
              (Number(formData.initialSupply) < 0 ||
                Number(formData.initialSupply) > 1000000000) && (
                <div className="text-[12px] text-red absolute bottom-[-24px] left-0">
                  The value must be between 1 and 1,000,000,000
                </div>
              )}
          </React.Fragment>

          <Button
            isDisabled={isDisabled}
            text="Create token"
            isLoading={isDeploying}
            type="submit"
            className="w-[460px] h-[58px] sm:w-full"
            variant={BUTTON_VARIANTS.LARGE}
          />
        </form>
      </div>
    </div>
  );
};

export default DeployToken;
