import React, { useState } from 'react';

import ConnectWalletModal from 'components/Modals/ConnectWalletModal';
import Button from 'components/Button';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';

const HeaderMain = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { setBlurBackground } = useBlurBackground();

  const onConnectWallet = () => {
    setBlurBackground();
    setIsOpened(true);
  };

  return (
    <React.Fragment>
      <div className="fixed z-50 header-bg top-0 left-0 w-full h-[92px] sm:h-[72px] flex items-center justify-center px-[40px] sm:px-4">
        <div className="flex items-center justify-between sm:justify-start max-w-[1440px] w-full mx-auto sm:max-w-full">
          <img className="h-[38px] sm:h-[32px]" src="/logoWithText.svg" alt="header logo" />
          <Button
            className="sm:hidden"
            text="Connect wallet"
            variant={BUTTON_VARIANTS.SMALL}
            onClick={onConnectWallet}></Button>
        </div>
      </div>
      {isOpened && <ConnectWalletModal isOpened={isOpened} setOpened={setIsOpened} />}
    </React.Fragment>
  );
};

export default HeaderMain;
