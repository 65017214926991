import React from 'react';
import { MENU_LIST } from './constants';
import { GroupMenu } from './Components/GroupMenu';
import { SocialList } from 'components/SocialList';

export const Menu = () => {
  return (
    <React.Fragment>
      <div className="flex flex-col w-full space-y-9">
        {Object.keys(MENU_LIST)?.map((itemGroup, index) => (
          <GroupMenu
            groupTitle={itemGroup}
            list={Object.values(MENU_LIST[itemGroup])}
            key={index}
          />
        ))}
      </div>
      <SocialList />
    </React.Fragment>
  );
};
