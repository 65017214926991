import React, { createContext, useContext, useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { delay } from 'helpers/delay';

export const WalletContext = createContext({
  network: null,
  signer: null,
  userAddress: '',
  connectWallet: () => {},
  disconnectWallet: () => {}
});

export const WalletProvider = ({ children }) => {
  const [userAddress, setUserAddress] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [network, setNetwork] = useState();
  const [signer, setSigner] = useState();

  const setProvider = (provider) => {
    setNetwork(provider);
    setSigner(window[provider]);
  };

  const connectPhantom = async (onlyIfTrusted) => {
    try {
      const response = await window.solana.connect({ onlyIfTrusted: onlyIfTrusted === true });
      setUserAddress(response.publicKey.toString());
      setProvider('solana');
    } catch (error) {}
  };

  const disconnectPhantom = () => {
    window.solana.disconnect();
    setUserAddress('');
  };

  const connectSolflare = async () => {
    try {
      await window.solflare.connect();
      setUserAddress(window.solflare.publicKey.toString());
      setProvider('solflare');
    } catch (error) {}
  };

  const disconnectSolflare = () => {
    window.solflare.disconnect();
    setUserAddress('');
  };

  const connectCoinbase = async () => {
    try {
      await window.coinbaseSolana.connect();
      setUserAddress(window.coinbaseSolana.publicKey.toString());
      setProvider('coinbaseSolana');
    } catch (error) {}
  };

  const disconnectCoinbase = async () => {
    await window.coinbaseSolana.disconnect();
    setUserAddress('');
  };

  const connectWallet = async (type, onlyIfTrusted) => {
    // window.solflare не успевает инициализироваться, поэтому ждем
    await delay(200);
    localStorage.setItem('wallet', type);
    if (window.solana && window.solana?.isPhantom && type === 'phantom') {
      connectPhantom(onlyIfTrusted);
    } else if (window.solflare && window.solflare?.isSolflare && type === 'solflare') {
      connectSolflare();
    } else if (window.coinbaseSolana && type === 'coinbase') {
      connectCoinbase();
    }
  };

  const disconnectWallet = (type) => {
    if (type === 'phantom') {
      disconnectPhantom();
    } else if (type === 'solflare') {
      disconnectSolflare();
    } else if (type === 'coinbase') {
      disconnectCoinbase();
    }
  };

  // Automatically try to connect to the wallet when the component mounts
  useEffect(() => {
    const wallet = localStorage.getItem('wallet');
    const load = async () => {
      if (userAddress || isInitialized) return;
      await connectWallet(wallet, true);
    };
    load().then(() => setIsInitialized(true));
  }, [userAddress, isInitialized]);

  if (!isInitialized) {
    const loader = (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
    return <React.Fragment>{loader}</React.Fragment>;
  }
  return (
    <WalletContext.Provider
      value={{
        network,
        signer,
        userAddress,
        connectWallet,
        disconnectWallet
      }}>
      {children}
    </WalletContext.Provider>
  );
};

export function useWallet() {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('Missing wallet context');
  }
  const { network, signer, userAddress, connectWallet, disconnectWallet } = context;

  return {
    network,
    signer,
    userAddress,
    connectWallet,
    disconnectWallet
  };
}
