import React from 'react';

export const INFO = [
  {
    title: 'What is Rocket Launcher?',
    desc: (
      <span>
        Rocket Launcher Token Creator is a smart contract that allows anyone to create tokens on
        Solana. You can customise supply, name, symbol, description and image. No coding experience
        needed.
        <br /> <br />
        All of it faster and cheaper than any other option - we use automation to cut the costs.
      </span>
    )
  },
  {
    title: 'What can I do with Rocket Launcher?',
    desc: (
      <span>
        You can create your own token and make it instantly tradeable on Raydium by deploying a
        liquidity pool via our interface.
      </span>
    )
  },
  {
    title: 'How does it work?',
    desc: (
      <span>
        First, you create a token via the interface. The tokens are airdropped to your wallet. Then
        the interface takes you to deploy a liquidity pool on Raydium. Here you can choose any
        market cap for your token and it will be shown as such on all screeners.
      </span>
    )
  },
  {
    title: 'How much time will it take to launch my token?',
    desc: (
      <span>
        The time depends on how busy Solana is at the moment, but it usually takes just a few
        seconds. If you have any issues, we are here for you. Ping us in the Telegram Support chat.
      </span>
    )
  },
  {
    title: 'How much will it cost?',
    desc: (
      <span>
        The token creation cost is currently around 0.65 Sol, including all fees necessary to create
        a token and deploy LP on Solana mainnet.
      </span>
    )
  },
  {
    title: 'How much liquidity do I need for the pool?',
    desc: (
      <span>
        None. Rocket Launcher uses V3 pools that are initially buy-only. When someone else buys the
        token, you will be able to sell it.
        <br />
        Again - you need no liquidity to deploy a liquidity pools with us.
      </span>
    )
  },
  {
    title: 'Where can I find my tokens?',
    desc: (
      <span>
        You can find your token on Dextools, Birdeye and Geckoterminal. Dexscreener does not support
        V3 pools yet. You can trade your tokens on Raydium.
      </span>
    )
  },
  {
    title: 'Is it safe to buy memecoins launched via Rocket Launcher?',
    desc: <span>Yes, our anti-rug system prevents liquidity providers from liquidity removal.</span>
  },
  {
    title: 'Which wallet can I use?',
    desc: <span>Phantom and Solflare</span>
  },
  {
    title: 'Why liquidity provider tokens of creators are burned automatically?',
    desc: (
      <span>
        To make sure there will be no rug pull. No LP tokens - no ways to withdraw liquidity and
        scam buyers.
      </span>
    )
  }
];
