import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';

export const Roadmap = ({ wrapperClass = '' }) => {
  const info = [
    {
      title: 'Phase 1',
      desc: (
        <span>
          -Token deployer <br />
          - Website <br />
          - Socials set up <br />
          - Liquidity burner <br />
          - KOL campaign <br />
        </span>
      ),
      year: '2023',
      classes:
        'space-y-[60px] h-full flex flex-col items-center left-[200px] absolute top-0 z-[20] sm:flex-row-reverse sm:left-[20%] sm:space-y-[0] sm:space-x-reverse sm:space-x-4 sm:top-[9%] sm:items-center sm:h-fit',
      isActive: true
    },
    {
      title: 'Phase 2',
      desc: (
        <span>
          - Market section development <br />
          - Personal dashboard <br />
          - Token manager <br />
          - Dashboard <br />
          - KOL campaign <br />
          - Introduction of comments in token details <br />
        </span>
      ),
      year: '2023',
      classes:
        'space-y-[60px] space-y-reverse items-center flex flex-col-reverse justify-start left-[495px] absolute top-[80px] z-[20] sm:flex-row sm:space-x sm:left-0 sm:space-y-[0] sm:space-x-4 sm:top-[31%] sm:left-[9%] sm:items-center sm:h-fit',
      isActive: true
    },
    {
      title: 'Phase 3',
      desc: (
        <span>
          - TON integration <br />
          - TRON integration <br />
          - Solana Bundler Tool <br />
          - Trending section <br />
          - Introduction of the KEROSENE points program <br />
        </span>
      ),
      year: '2024',
      classes:
        'space-y-[20px] h-full flex flex-col left-[795px] absolute top-0 z-[20] sm:flex-row-reverse sm:left-[21%] sm:space-y-[0] sm:space-x-reverse sm:space-x-4 sm:top-[55.5%] sm:items-center sm:h-fit',
      isActive: true
    },
    {
      title: 'Phase 4',
      desc: (
        <span>
          - Tasks and achievements dashboard <br />
          - AI token analyst <br />
          - Influencer personal dashboard <br />
          - Introduction of marketing tools for creators <br />
        </span>
      ),
      year: '2024',
      classes:
        'space-y-[60px] space-y-reverse flex flex-col-reverse left-[1090px] absolute top-[80px] z-[20] sm:flex-row sm:left-[10%] sm:space-y-[0] sm:space-x-4 sm:top-[78%] sm:items-center sm:h-fit',
      isActive: false
    }
  ];

  const [lenghtLastActive, setLenghtLastActive] = useState(0);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();

  useEffect(() => {
    findLastActive();
  }, [width]);

  const findLastActive = () => {
    const temp = info.slice().reverse();
    const findItem = temp.find((item) => !!item.isActive);

    const tempPosition = document.getElementById(findItem?.title);

    const tempPositionWrapper = document.getElementById('gradient');
    const positionWrapper = tempPositionWrapper?.getBoundingClientRect();

    if (!!tempPosition) {
      const position = tempPosition?.getBoundingClientRect();

      if (width <= 992) {
        setLenghtLastActive(position?.y - positionWrapper?.y + position?.height * 0.5);
      } else {
        setLenghtLastActive(position?.x - positionWrapper?.x + position?.width * 0.5);
      }
    }
  };

  const styleGradient = useMemo(() => {
    if (width <= 992) {
      return { height: `${lenghtLastActive}px` };
    } else {
      return { width: `${lenghtLastActive}px` };
    }
  }, [lenghtLastActive]);

  return (
    <div className={`flex flex-col items-center space-y-[60px] overflow-hidden ${wrapperClass}`}>
      <div className="flex flex-col items-center text-center space-y-3">
        <Typography className="text-white" variant={TYPOGRAPHY_VARIANTS.HEADER_H2} text="Roadmap" />
      </div>
      <div className="flex justify-center items-start">
        <div className="h-full relative w-[1440px] overflow-x-auto overflow-y-hidden sm:overflow-x-hidden sm:w-fit ">
          <div id={'gradient'} className="relative h-[450px] sm:h-[950px] sm:w-[375px] ">
            <div
              style={styleGradient}
              className="z-[11] absolute shadow-roadmap top-0 left-0 h-full w-[20%] sm:w-full sm:h-[11%]"
            />
            {/* <div className="z-[11] absolute shadow-roadmap top-0 left-0 h-full hidden sm:block w-full h-[11%]" /> */}
            <div className="z-[0] absolute roadmap_point top-0 right-0 w-[100%] h-full roadmap-bg" />
            <img
              className="z-[20] absolute top-0 left-0 w-[1500px] h-[450px] sm:h-[950px] sm:hidden roadmap_shadow "
              src="/roadmap/road.png"
            />
            <img
              className="z-[20] absolute top-0 left-0 w-auto h-[950px] hidden sm:flex roadmap_shadow_mob"
              src="/roadmap/mobRoad.png"
            />
          </div>
          {info.map((item, itemIndex) => {
            return (
              <div className={item.classes}>
                <div className="flex flex-col space-y-3 max-w-[154px] sm:max-w-[175px]">
                  <div className="flex items-center justify-start space-x-3">
                    <span className="text-white text-[16px] sm:text-[14px] leading-[16px]">
                      {item.title}
                    </span>
                  </div>
                  <span className="text-white-500 font-light text-[14px] sm:text-[12px] sm:leading-[16px]">
                    {item.desc}
                  </span>
                </div>

                <img
                  id={item?.title}
                  src={`/roadmap/${itemIndex + 1}.png`}
                  className={`${item?.isActive ? '' : 'grayscale brightness-[10%]'} w-auto w-[155px] h-[155px] sm:w-[77px] sm:h-[77px]`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
