import React from 'react';

import { DashboardLayout } from 'layouts/DashboardLayout';
import DashboardTableController from 'controllers/DashboardTableController';
import DashboardStatistics from 'components/DashboardStatistics';

const Dashboard = () => {
  return (
    <DashboardLayout title="Dashboard">
      <DashboardStatistics />
      <div className="overflow-auto">
        <DashboardTableController />
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
