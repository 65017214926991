import React, { useEffect } from 'react';

import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { useRequest } from 'helpers/hooks/useRequest';
import { TokensRepository } from 'connectors/repositories/tokens';
import { getToFixedNumber } from 'helpers/number';

const DashboardStatistics = () => {
  const { call, data } = useRequest(TokensRepository.getTokensStats);

  useEffect(() => {
    call();
  }, []);

  return (
    <div className="flex space-x-2 mb-9">
      <div className="py-6 sm:py-[17px] flex-1 flex sm:flex-col items-center justify-center border border-white-100 rounded-2xl space-x-2 sm:space-x-0 sm:space-y-2">
        <Typography
          className="text-white-500 uppercase"
          variant={TYPOGRAPHY_VARIANTS.BODY_S}
          text="24h volume"
        />
        <Typography
          className="text-white"
          variant={TYPOGRAPHY_VARIANTS.BODY_S}
          text={`$${getToFixedNumber(data?.result?.volumeUsd24h, 4)}`}
        />
      </div>
      <div className="py-6 sm:py-[17px] flex-1 flex sm:flex-col items-center justify-center border border-white-100 rounded-2xl space-x-2 sm:space-x-0 sm:space-y-2">
        <Typography
          className="text-white-500 uppercase"
          variant={TYPOGRAPHY_VARIANTS.BODY_S}
          text="24H TXNS"
        />
        <div className="flex items-center justifyce-center space-x-2">
          <Typography
            className="text-white"
            variant={TYPOGRAPHY_VARIANTS.BODY_S}
            text={data?.result?.txs24h}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardStatistics;
