import React, { useEffect, useState } from 'react';
import JSBI from 'jsbi';
import { useDispatch, useSelector } from 'react-redux';
import * as web3 from '@solana/web3.js';

import { useWallet } from 'contexts/wallet';
import { ammConfig10000 } from 'utils/amm-configs';
import { MIN_MARKET_CAP, MAX_MARKET_CAP } from './constants';

import Button from 'components/Button';
import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import { resetLiquidityInfo } from 'store/userSlice';
import { getAddLiquidity } from 'store/userSlice/selectors';
import { useRequest } from 'helpers/hooks/useRequest';
import { TokensRepository } from 'connectors/repositories/tokens';
import TextInput from 'components/Inputs/TextInput';
import NumberInput from 'components/Inputs/NumberInput';
import SelectInput from 'components/Inputs/SelectInput';
import Radio from 'components/Radio';

const AddLiquidity = ({ onDeploy, isDeploying, initialSupply, setTokenImage, setData }) => {
  const [formData, setFormData] = useState({
    initialSupply: initialSupply?.toString() || '',
    tokenAddress: '',
    marketCap: '',
    poolConfig: ammConfig10000,
    liquidityPoolPercent: 100
  });
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const dispatch = useDispatch();
  const { userAddress } = useWallet();
  const { data, call } = useRequest(TokensRepository.getUserTokens, [
    userAddress,
    '&no_liquidity=true'
  ]);

  const handleChangeRadio = (value) => {
    setFormData({
      ...formData,
      liquidityPoolPercent: value
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (formData[name] === value) return;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onOptionsClick = (value) => {
    setSelectedOption(value);
    setTokenImage(value?.imageUri);

    setData({
      tokenMint: new web3.PublicKey(value.mint),
      tokenName: value.name,
      tokenSymbol: value.symbol,
      initialSupply: value.supply
    });
    setFormData({
      ...formData,
      initialSupply: value.supply,
      tokenAddress: value.mint,
      marketCap: '',
      poolConfig: ammConfig10000
    });
  };

  const handleDeploy = (e) => {
    e.preventDefault();
    onDeploy({
      initialSupply: JSBI.BigInt(formData.initialSupply),
      poolConfig: formData.poolConfig,
      marketCap: JSBI.BigInt(formData.marketCap),
      liquidityPoolPercent: formData.liquidityPoolPercent
    });
  };

  const liquidityInfoStore = useSelector(getAddLiquidity);
  const isUserFromDashboard = liquidityInfoStore?.mint;

  const isDisabled = !userAddress || isDeploying || !formData.tokenAddress || !formData.marketCap;

  useEffect(() => {
    if (liquidityInfoStore?.imageUri) {
      setTokenImage(liquidityInfoStore?.imageUri);
    }

    if (isUserFromDashboard) {
      setData({
        tokenMint: new web3.PublicKey(liquidityInfoStore.mint),
        tokenName: liquidityInfoStore.name,
        tokenSymbol: liquidityInfoStore.symbol,
        initialSupply: liquidityInfoStore.supply
      });
      setFormData({
        liquidityPoolPercent: 100,
        initialSupply: liquidityInfoStore.supply,
        tokenAddress: liquidityInfoStore.mint,
        marketCap: '',
        poolConfig: ammConfig10000
      });
    } else {
      call();
    }

    return () => {
      dispatch(resetLiquidityInfo());
    };
  }, []);

  useEffect(() => {
    if (data?.result) {
      setSelectOptions(
        data?.result.reduce((result, item) => {
          return [
            ...result,
            {
              name: item.name,
              symbol: item.symbol,
              supply: item.supply,
              mint: item.mint,
              imageUri: item.imageUri
            }
          ];
        }, [])
      );
    }
  }, [data]);

  return (
    <div className="flex flex-col mx-auto sm:mx-0 overflow-auto pr-[280px] sm:pr-0">
      {!selectOptions.length && !liquidityInfoStore?.mint ? (
        <div className="flex mt-[120px] ml-auto mr-auto items-center justify-center flex-col w-[460px] sm:w-full">
          <img className="w-[48px] h-[48px]" src="/smileIcon.png" alt="smile Icon" />
          <Typography
            className="text-white opacity-[0.5] w-[78%] text-center mt-3"
            text="You do not have tokens to create liquidity pool"
            variant={TYPOGRAPHY_VARIANTS.BODY_M}
          />
        </div>
      ) : (
        <React.Fragment>
          <div className="flex flex-col items-center justify-center space-y-[12px] max-w-[460px] sm:max-w-full mt-[48px] sm:mt-[20px]">
            <Typography
              className="text-white"
              text="Starting market cap"
              variant={TYPOGRAPHY_VARIANTS.HEADER_H2}
            />
            <Typography
              className="text-white text-center w-[420px] sm:w-[280px] opacity-[0.5]"
              text="The starting market cap affects the initial token price and its level of volatility"
              variant={TYPOGRAPHY_VARIANTS.BODY_M}
            />
          </div>

          <div className="w-full max-w-[460px] sm:max-w-full py-[48px]">
            <form
              className="w-full flex flex-col items-center space-y-[32px]"
              onSubmit={handleDeploy}>
              {isUserFromDashboard ? (
                <TextInput
                  disabled={!userAddress || isDeploying || liquidityInfoStore.mint}
                  title="Token address"
                  id="tokenAddress"
                  value={liquidityInfoStore.mint}
                  placeholder="Example, 14mcTJ93m7xVuFSdzgjzy4wkRnzVZihvB7cx8MGszSbY"
                />
              ) : (
                <SelectInput
                  disabled={!userAddress || isDeploying}
                  title="Token address"
                  id="tokenAddress"
                  value={selectedOption.mint}
                  onOptionsClick={onOptionsClick}
                  options={selectOptions}
                  placeholder="Example, 14mcTJ93m7xVuFSdzgjzy4wkRnzVZihvB7cx8MGszSbY"
                />
              )}
              <div className="flex flex-col relative items-start justify-center space-y-[12px] w-full">
                <NumberInput
                  className="relative w-full"
                  title="Market cap size in usd"
                  id="marketCap"
                  min="10000"
                  max="10000000"
                  value={formData.marketCap}
                  placeholder="Example, 20 000"
                  handleChange={(e) => handleChange(e)}
                />
                <span className="absolute top-[36px] sm:top-[30px] right-12 text-white-500">$</span>
                {!!formData.marketCap &&
                  (Number(formData.marketCap) < MIN_MARKET_CAP ||
                    Number(formData.marketCap) > MAX_MARKET_CAP) && (
                    <Typography
                      className="pl-[24px] mt-3 text-red"
                      text="The value must be between 10,000 and 10,000,000"
                      variant={TYPOGRAPHY_VARIANTS.CAPTION}
                    />
                  )}
              </div>
              <div className="flex flex-col relative items-start justify-center space-y-[12px] w-full">
                <Radio
                  onClick={handleChangeRadio}
                  value={formData.liquidityPoolPercent}
                  title="Send to Liquidity Pool"
                  options={[100, 95]}
                />
                <Typography
                  className="pl-[24px] mt-3 text-white opacity-[0.5]"
                  text={`${formData.liquidityPoolPercent}% tokens will go in Liquidity Pool.`}
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <Button
                isDisabled={isDisabled}
                text="Deploy"
                isLoading={isDeploying}
                type="submit"
                className="w-[460px] h-[58px] sm:w-full"
                variant={BUTTON_VARIANTS.LARGE}
              />
              <Typography
                className="!mt-4 w-[388px] sm:w-auto text-center text-white opacity-[0.5]"
                text="Click Deploy and confirm the transaction in your wallet. The pool with your tokens will be deployed in several minutes."
                variant={TYPOGRAPHY_VARIANTS.CAPTION}
              />
            </form>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default AddLiquidity;
