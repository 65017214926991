import React, { useMemo, useState } from 'react';
import Typography from '../Typography';
import { TYPOGRAPHY_VARIANTS } from '../Typography/constants';

const StepByStep = ({ step }) => {
  const [supButtonOpen, setSupButtonOpen] = useState(false);

  const supButtonContent = useMemo(() => {
    if (step === 1) {
      return {
        title: 'What is the Liquidity pool?',
        content: () => {
          return (
            <div className="flex flex-col space-y-[12px]">
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="1. Choose size your liquidity pool in SOL. 95% tokens will go in LP."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="2. Click on DEPLOY, accept the transactions and wait until your tokens ready."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="What is Liquidity Pool?"
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
                <br />
                <Typography
                  className="text-white opacity-[0.5]"
                  text="A liquidity pool in crypto tokens is a collection of tokens held by a
                  decentralized exchange (DEX) that allows users to trade tokens directly with each
                  other without the need for a centralized intermediary. This pool provides the
                  necessary liquidity for trades to occur smoothly. Liquidity pool will burn
                  automatically and does not require additional payment"
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
            </div>
          );
        }
      };
    }
  }, [step]);

  return (
    <React.Fragment>
      <div className="fixed bottom-[90px] right-[40px] flex flex-col items-end max-w-[350px] space-y-2">
        {supButtonOpen && (
          <div className="opacityBackgroundBlurClass flex flex-col space-y-2.5 bg-white-100 rounded-[32px] w-auto sm:w-[94%] max-h-[85vh] overflow-auto p-4 text-white-500">
            <Typography
              className="text-white"
              text={supButtonContent?.title}
              variant={TYPOGRAPHY_VARIANTS.BODY_S}
            />
            <div className="text-[12px]">{supButtonContent?.content()}</div>
          </div>
        )}
      </div>
      <button
        className={`fixed bottom-[24px] right-[40px] sm:bottom-4 sm:right-4 cursor-pointer flex items-center justify-center ${supButtonOpen ? 'p-[0.5px] btn-gradient' : 'p-[0.5px] bg-[#212121]'} rounded-full max-w-max h-[56px]`}
        onClick={() => setSupButtonOpen(!supButtonOpen)}>
        <div
          className={`${supButtonOpen ? 'bg-[#212121]' : 'bg-transparent'} w-full h-full rounded-full flex items-center justify-center px-4 space-x-[6px] text-white`}>
          <img src="/infoIcon.svg" alt="info icon" />
          {!supButtonOpen && (
            <Typography
              className={'sm:hidden'}
              text={supButtonContent?.title}
              variant={TYPOGRAPHY_VARIANTS.BODY_S}
            />
          )}
        </div>
      </button>
    </React.Fragment>
  );
};

export default StepByStep;
