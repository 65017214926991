import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Avatar from '../TableAvatar';
import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { setLiquidityInfo } from 'store/userSlice';

const TableRow = ({ className, imageUri, name, mint, supply, symbol }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onRowClick = () => {
    dispatch(setLiquidityInfo({ mint, name, imageUri, supply, symbol }));
    navigate('/createLiquidityPool');
  };

  return (
    <React.Fragment>
      <div
        onClick={onRowClick}
        className={clsx(
          'sm:hidden relative cursor-pointer group flex w-full justify-between mt-3 px-4 py-3 items-center bg-white-10 hover:bg-white-50 rounded-2xl border border-white-100 space-x-4',
          className
        )}>
        <div className="flex-1 flex items-center">
          <Avatar imageSrc={imageUri} />
          <Typography
            className="ml-3 text-white-1000 text-ellipsis w-[100px] overflow-hidden"
            text={name}
          />
        </div>
        <div className="flex items-center px-6 py-3 bg-white-50 rounded-[100px]">
          <Typography
            className="text-white"
            variant={TYPOGRAPHY_VARIANTS.BODY_S}
            text="Liquidity pool"
          />
        </div>
      </div>

      <div
        onClick={onRowClick}
        className={clsx(
          'hidden relative cursor-pointer group sm:flex w-full justify-between mt-3 px-4 py-3 items-center bg-white-10 hover:bg-white-50 rounded-2xl border border-white-100 space-x-4',
          className
        )}>
        <div className="flex-1 flex items-center space-x-2">
          <Avatar className="w-10 h-10" imageSrc={imageUri} />
          <div className="flex flex-col items-start justify-between space-y-1">
            <Typography
              className="text-white-1000 text-ellipsis w-[100px] overflow-hidden"
              text={name}
            />
          </div>
        </div>
        <div className="flex flex-col items-end justify-between space-y-1">
          <div className="flex items-center px-6 py-3 bg-white-50 rounded-[100px]">
            <Typography
              className="text-white"
              variant={TYPOGRAPHY_VARIANTS.BODY_S}
              text="Liquidity pool"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableRow;
