import React from 'react';
import { Accordion } from 'react-accessible-accordion';

import { DashboardLayout } from 'layouts/DashboardLayout';
import Typography from 'components/Typography';
import { INFO } from 'components/Faq/constants';
import FaqItem from 'components/Faq/FaqItem';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';

const Faq = () => {
  return (
    <DashboardLayout>
      <div className="flex mx-auto justify-center sm:w-full text-white h-full pr-[280px] sm:pr-0">
        <div className="flex flex-col sm:px-6">
          <div className="flex flex-col items-center space-y-6 max-w-[460px] sm:max-w-full w-full">
            <Typography className="text-white" text="FAQ" variant={TYPOGRAPHY_VARIANTS.HEADER_H2} />
            <Accordion allowZeroExpanded allowMultipleExpanded className="w-full space-y-3 ">
              {INFO.map((item) => {
                return <FaqItem {...item} key={item.title} />;
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Faq;
