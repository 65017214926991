import React, { useState, useCallback, useEffect, useRef } from 'react';

import MainTable from 'components/Tables/MainTable';
import { useRequest } from 'helpers/hooks/useRequest';
import { TokensRepository } from 'connectors/repositories/tokens';
import { PAGE_SIZE } from './constants';
import { useWallet } from 'contexts/wallet';

const TokensTableController = () => {
  const { userAddress } = useWallet();
  const currentPage = useRef(0);
  const [queryParams, setQueryParams] = useState(`page=${currentPage.current}&size=${PAGE_SIZE}`);
  const [isShowLoadingButton, setIsShowLoadingButton] = useState(true);

  const requestMapper = (prev, data) => {
    if (data?.result?.length < PAGE_SIZE) setIsShowLoadingButton(false);
    if (Object.keys(prev)?.length) {
      return { result: [...prev.result, ...data.result] };
    }

    return data;
  };

  const { data, call } = useRequest(TokensRepository.getTokens, [queryParams], requestMapper);

  useEffect(() => {
    if (!userAddress) {
      call();
    }
  }, [queryParams]);

  const onLoadMore = useCallback(async () => {
    currentPage.current += 1;
    setQueryParams(`page=${currentPage.current}&size=${PAGE_SIZE}`);
  }, []);

  return (
    <MainTable data={data} onLoadMore={onLoadMore} isShowLoadingButton={isShowLoadingButton} />
  );
};

export default TokensTableController;
