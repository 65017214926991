import axios from 'axios';
import Qs from 'qs';
import { destroyCookie, parseCookies } from 'nookies';
import { IS_DEV } from "../config";

//for interceptors etc.

export const instance = axios.create({
  baseURL: IS_DEV ? process.env.REACT_APP_DEVNET_PROXY_URL : process.env.REACT_APP_MAINET_PROXY_URL,
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' });
  }
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      destroyCookie(null, 'apiToken');
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

export const requestApi = async (method, url, data) => {
  const config = {
    method: method,
    url: url,
    params: {}
  };

  if (data) {
    switch (method) {
      case 'post':
      case 'put':
      case 'patch':
        config.data = data;
        break;
      default:
        config.params = { ...config.params, ...data };
        break;
    }
  }
  config.data = data;

  const cookies = parseCookies();

  instance.defaults.headers.Authorization = cookies?.apiToken
    ? `Bearer ${cookies?.apiToken}`
    : null;

  try {
    const { data } = await instance.request(config);

    return data;
  } catch (error) {
    throw error;
  }
};
