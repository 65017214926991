import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import Notification from 'components/Notification';
import CookieLayout from 'layouts/CookieLayout';
import { WalletProvider } from 'contexts/wallet';
import { NotificationsProvider } from 'contexts/notifications';
import store from 'store';

import { RoutesFind } from 'RoutesFind';

import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <Router>
          <WalletProvider>
            <CookieLayout>
              <SnackbarProvider
                className="top-[70px]"
                maxSnack={6}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                content={(key, data) => (
                  <div>
                    <Notification id={key} notification={data} />
                  </div>
                )}>
                <NotificationsProvider>
                  <RoutesFind />
                </NotificationsProvider>
              </SnackbarProvider>
            </CookieLayout>
          </WalletProvider>
        </Router>
      </ReduxProvider>
    </ThemeProvider>
  );
}

export default App;
