import React, { useState, useCallback, useEffect, useRef } from 'react';

import DashboardTable from 'components/Tables/DashboardTable';
import { useRequest } from 'helpers/hooks/useRequest';
import { useWallet } from 'contexts/wallet';
import { TokensRepository } from 'connectors/repositories/tokens';
import { PAGE_SIZE } from './constants';

const DashboardTableController = () => {
  const { userAddress } = useWallet();
  const currentPage = useRef(0);
  const [queryParams, setQueryParams] = useState(`page=${currentPage.current}&size=${PAGE_SIZE}`);
  const [isShowLoadingButton, setIsShowLoadingButton] = useState(true);

  const requestMapper = (prev, data) => {
    if (data?.result?.length < PAGE_SIZE) setIsShowLoadingButton(false);
    if (Object.keys(prev)?.length) {
      return { result: [...prev.result, ...data.result] };
    }

    return data;
  };

  const { data, call } = useRequest(
    TokensRepository.getUserTokens,
    [userAddress, queryParams],
    requestMapper
  );

  useEffect(() => {
    call();
  }, [queryParams]);

  const onLoadMore = useCallback(async () => {
    currentPage.current += 1;
    setQueryParams(`page=${currentPage.current}&size=${PAGE_SIZE}`);
  }, []);

  return (
    <DashboardTable data={data} onLoadMore={onLoadMore} isShowLoadingButton={isShowLoadingButton} />
  );
};

export default DashboardTableController;
