import React from 'react';

import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';

const TableHeader = () => {
  return (
    <div className="flex justify-between items-center px-4 space-x-4 sm:hidden">
      <Typography
        className="flex-1 opacity-50 text-white-1000"
        text="TOKEN"
        variant={TYPOGRAPHY_VARIANTS.XS}
      />
      <Typography
        className="flex-1 opacity-50 text-white-1000"
        text="PRICE"
        variant={TYPOGRAPHY_VARIANTS.XS}
      />
      <Typography
        className="flex-1 opacity-50 text-white-1000"
        text="MCAP"
        variant={TYPOGRAPHY_VARIANTS.XS}
      />
      <Typography
        className="flex-1 opacity-50 text-white-1000"
        text="VOLUME"
        variant={TYPOGRAPHY_VARIANTS.XS}
      />
      <Typography
        className="flex-1 opacity-50 text-white-1000"
        text="24h"
        variant={TYPOGRAPHY_VARIANTS.XS}
      />
      <Typography
        className="flex-1 opacity-50 text-white-1000"
        text="Created by"
        variant={TYPOGRAPHY_VARIANTS.XS}
      />
      <Typography
        className="flex-1 opacity-50 text-white-1000"
        text="LINKS"
        variant={TYPOGRAPHY_VARIANTS.XS}
      />
    </div>
  );
};

export default TableHeader;
