import { useWallet } from 'contexts/wallet';
import { useBlurBackground } from './useBlurBackground';

export const useLogOut = () => {
  const { disconnectWallet } = useWallet();
  const { resetBlurBackground } = useBlurBackground();

  const logOut = () => {
    resetBlurBackground();
    localStorage.removeItem('wallet');
    if (window.solana?.isConnected) {
      disconnectWallet('phantom');
    } else if (window.coinbaseSolana?.isConnected) {
      disconnectWallet('coinbase');
    } else if (window.solflare?.isConnected) {
      disconnectWallet('solflare');
    }
  };

  return {
    logOut
  };
};
