import React from 'react';
import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';

export const Item = ({ title = '', desc = '', img = '' }) => {
  return (
    <div className="h-full flex flex-col space-y-6 rounded-[32px] bg-white-10 p-5 border border-white-100">
      <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
        className="h-[220px] w-full rounded-[12px] bg-white-500"
      />
      <div className="flex flex-col space-y-2">
        <Typography variant={TYPOGRAPHY_VARIANTS.HEADER_H4} text={title} />
        <Typography className="text-white-500" variant={TYPOGRAPHY_VARIANTS.BODY_S} text={desc} />
      </div>
    </div>
  );
};
