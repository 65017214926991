import React, { useRef } from 'react';

import ReactPortal from 'components/Portal';
import Typography from 'components/Typography';
import { useClickOutside } from 'helpers/hooks/useClickOutside';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import Button from 'components/Button';
import { useLogOut } from 'helpers/hooks/useLogOut';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';

const LogOutModal = ({ isOpened, setIsOpened }) => {
  const ref = useRef(null);
  const { resetBlurBackground } = useBlurBackground();
  const { logOut } = useLogOut();

  const handleCloseModal = () => {
    resetBlurBackground();
    setIsOpened(false);
  };

  useClickOutside(ref, () => {
    handleCloseModal();
  });

  return (
    <ReactPortal isOpen={isOpened}>
      <div
        ref={ref}
        className="flex w-[600px] modal text-white fixed top-0 pt-[36px] left-0 z-[999999]">
        <div className="w-full flex justify-center relative py-[50px] overflow-auto  bg-black-1000 px-[60px] pt-[80px] pb-[48px] rounded-[48px]">
          <div
            onClick={handleCloseModal}
            className="z-[2] cursor-pointer absolute top-[30px] right-[30px] bg-transparent flex items-center justify-center rounded-full w-[44px] h-[44px]">
            <img className="w-[24px] h-[24px]" src="/closeIcon.svg" alt="close icon" />
          </div>

          <div className="z-[2] flex-col justify-end sm:px-6">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.HEADER_H3}
              text="Are you sure you want to log out?"
            />
            <div className="flex items-center justify-center mt-4 sm:mt-[28px]">
              <Button
                onClick={handleCloseModal}
                className="h-[40px] w-[130px] mr-4"
                variant={BUTTON_VARIANTS.LARGE}
                text="Cancel"
              />
              <Button
                onClick={logOut}
                className="h-[40px] w-[130px]"
                variant={BUTTON_VARIANTS.SMALL}
                text="Log out"
              />
            </div>
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default LogOutModal;
