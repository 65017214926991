import { useCallback, useState } from 'react';
import JSBI from 'jsbi';
import BN from 'bn.js';
import * as web3 from '@solana/web3.js';
import * as token from '@solana/spl-token';

import { useNotifications } from 'contexts/notifications';
import { useWallet } from 'contexts/wallet';
import { LOOKUP_TABLE_ADDRESS, PROGRAM_ID, SOLANA_ENDPOINT, TOKEN_DECIMALS } from 'config';
import {
  buildComputeBudgetInstructions,
  generateBaseTokenKeypair,
  getPdaMetadataKey
} from 'utils/raydium-utils';
import { buildInstructionFromIDL } from 'utils/utils';
import TOKEN_DEPLOYER_IDL from 'idls/token_deployer.json';
import { COMPUTE_BUDGET_CONFIG } from './constants';

const useCreateToken = () => {
  const [isDeploying, setIsDeploying] = useState(false);
  const { userAddress, signer } = useWallet();
  const { tx } = useNotifications();

  const createToken = useCallback(
    async (
      tokenMintKeypair,
      tokenName,
      tokenSymbol,
      tokenMetadataURI,
      initialSupplyInTokensJSBI
    ) => {
      const payer = new web3.PublicKey(userAddress);
      const connection = new web3.Connection(SOLANA_ENDPOINT, 'confirmed');
      // Convert initial supply:
      const eDecimalsJSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(TOKEN_DECIMALS));
      const initialSupplyJSBI = JSBI.toNumber(
        JSBI.multiply(initialSupplyInTokensJSBI, eDecimalsJSBI)
      );
      const initialSupplyBN = new BN(initialSupplyJSBI.toString());
      const initialSupply = initialSupplyJSBI.toString();
      // Extract token mint and token account:
      const tokenMint = tokenMintKeypair.publicKey;
      const tokenAccount = await token.getAssociatedTokenAddress(tokenMint, payer, false);
      const tokenMetadataAccount = getPdaMetadataKey(tokenMint).publicKey;
      console.log('Deploying token:', tokenName, tokenSymbol, initialSupply);
      console.log('Token mint:', tokenMint.toString());
      console.log('Token ATA:', tokenAccount.toString());
      // 1. Build compute budget instructions:
      const computeBudgetInstructions = buildComputeBudgetInstructions(COMPUTE_BUDGET_CONFIG);
      // 2. Build program instruction:
      const programInstruction = buildInstructionFromIDL(
        TOKEN_DEPLOYER_IDL,
        'create_token',
        {
          payer: payer,
          token_mint: tokenMint,
          token_account: tokenAccount,
          token_metadata_account: tokenMetadataAccount,

          system_program: new web3.PublicKey('11111111111111111111111111111111'),
          token_program: new web3.PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
          associated_token_program: new web3.PublicKey(
            'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
          ),
          metadata_program: new web3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
          rent_program: new web3.PublicKey('SysvarRent111111111111111111111111111111111')
        },
        {
          amount: initialSupplyBN,
          name: tokenName,
          symbol: tokenSymbol,
          uri: tokenMetadataURI
        },
        PROGRAM_ID
      );
      // All instructions:
      const instructions = [];
      instructions.push(...computeBudgetInstructions);
      instructions.push(programInstruction);

      // Getting Lookup Table Account:
      const lookupTableAddress = new web3.PublicKey(LOOKUP_TABLE_ADDRESS);
      const lookupTableAccount = (await connection.getAddressLookupTable(lookupTableAddress)).value;

      // Building transaction:
      const latestBlockhash = (await connection.getLatestBlockhash('finalized')).blockhash;
      const messageV0 = new web3.TransactionMessage({
        payerKey: payer,
        recentBlockhash: latestBlockhash,
        instructions: instructions
      }).compileToV0Message([lookupTableAccount]);
      const transactionV0 = new web3.VersionedTransaction(messageV0);
      transactionV0.sign([tokenMintKeypair]);
      return await signer.signAndSendTransaction(transactionV0);
    },
    [userAddress, signer]
  );

  const deploy = useCallback(
    async (params, onSuccess = () => {}) => {
      try {
        const tokenMintKeypair = generateBaseTokenKeypair();
        const tokenName = params.tokenName;
        const tokenSymbol = params.tokenSymbol;
        const tokenMetadataURI = params.tokenMetadataURI;
        const initialSupplyInTokensJSBI = params.initialSupply;
        setIsDeploying(true);
        await tx('Creating token...', 'Token created!', () =>
          createToken(
            tokenMintKeypair,
            tokenName,
            tokenSymbol,
            tokenMetadataURI,
            initialSupplyInTokensJSBI
          )
        );
        onSuccess &&
          onSuccess({
            tokenMint: tokenMintKeypair.publicKey
          });
      } catch (e) {
        console.warn(e);
      } finally {
        setIsDeploying(false);
      }
    },
    [createToken, tx]
  );

  return {
    isDeploying,
    deploy
  };
};

export default useCreateToken;
