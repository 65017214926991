import { BaseRepository } from "./base";
import { requestApi } from "../api";

class Repository extends BaseRepository {
  getTokens = (query) =>
    requestApi("get", `${this.path}/tokens?${query}`);
  getUpdateTokens = () =>
      requestApi("get", `${this.path}/update`);
  getUserTokens = (user, query) =>
    requestApi("get", `${this.path}/user/${user}/tokens?${query}`);
  getTokensStats = () =>
      requestApi("get", `${this.path}/stats`);
}

export const TokensRepository = new Repository("");
