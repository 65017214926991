import React, { useMemo, useState } from 'react';

import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { useWallet } from 'contexts/wallet';
import { formatUserAddress } from 'helpers/string';
import { Burger } from 'components/Burger';
import LogOutModal from '../../Modals/LogOutModal';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';

export const UserInfo = () => {
  const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { userAddress } = useWallet();
  const { setBlurBackground } = useBlurBackground();

  const onDisconnectClick = () => {
    setBlurBackground();
    setIsOpenLogOutModal(true);
  };

  const headerAvatar = useMemo(() => {
    if (window.solana?.isConnected) return '/wallets/phantom.png';
    if (window.coinbaseSolana?.isConnected) return '/wallets/coinbase.png';
    if (window.solflare?.isConnected) return '/wallets/solflare.png';

    return '/wallets/phantom.png';
  }, [userAddress]);

  return (
    <div className="flex relative items-center justify-end text-white">
      {/*<div className="px-2 py-1 flex items-center rounded-[100px] bg-white-100">*/}
      {/*  <img className="h-[36px] w-[36px] mr-1" src="/logoOnBlack.png" alt="header rocket logo" />*/}
      {/*  <Typography className="text-white" text="23234" variant={TYPOGRAPHY_VARIANTS.BODY_S} />*/}
      {/*</div>*/}

      <div className="ml-1 px-2 py-1 flex items-center rounded-[100px] bg-white-100 sm:hidden">
        <img
          className="h-[36px] w-[36px] mr-1 rounded-[20px]"
          src={headerAvatar}
          alt="header wallet icon"
        />
        <Typography
          className="text-white"
          text={`ID ${formatUserAddress(userAddress)}`}
          variant={TYPOGRAPHY_VARIANTS.BODY_S}
        />
      </div>

      <div
        onClick={() => setIsOpenMenu((prev) => !prev)}
        className="ml-1 p-1 flex items-center justify-center rounded-[100px] bg-white-100">
        {isOpenMenu ? (
          <img className="h-[36px] w-[36px]" src="/closeIcon.svg" alt="header menu icon" />
        ) : (
          <img className="h-[36px] w-[36px]" src="/menuIcon.png" alt="header menu icon" />
        )}
      </div>

      {isOpenMenu && (
        <div className="sm:hidden absolute z-50 top-[60px] rounded-[24px] w-[232px] opacityBackgroundBlurClass py-3 px-4 flex flex-col">
          <div className="flex cursor-not-allowed items-center">
            <img
              className="h-[24px] w-[24px] opacity-[0.3]"
              src="/settingsIcon.png"
              alt="settings icon"
            />
            <Typography
              className="text-white ml-3 opacity-[0.3]"
              text="Settings"
              variant={TYPOGRAPHY_VARIANTS.BODY_M}
            />
          </div>
          <div onClick={onDisconnectClick} className="flex mt-[24px] cursor-pointer items-center">
            <img className="h-[24px] w-[24px]" src="/logOutIcon.png" alt="logout icon" />
            <Typography
              className="ml-3 text-white"
              text="Log out"
              variant={TYPOGRAPHY_VARIANTS.BODY_M}
            />
          </div>
        </div>
      )}
      {isOpenLogOutModal && (
        <LogOutModal isOpened={isOpenLogOutModal} setIsOpened={setIsOpenLogOutModal} />
      )}
      <Burger isOpened={isOpenMenu} />
    </div>
  );
};
