import React, { useState } from 'react';

import { DashboardLayout } from 'layouts/DashboardLayout';
import AddLiquidity from 'components/CreateLiquidityPool/AddLiquidity';
import FinalStep from 'components/CreateLiquidityPool/FinalStep';
import useAddLiquidity from 'helpers/hooks/useAddLiquidity';
import StepByStep from 'components/CreateLiquidityPool/StepByStep';

const CreateLiquidityPool = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const [tokenImage, setTokenImage] = useState(null);

  const { deploy: addLiquidity, isDeploying: isAdding } = useAddLiquidity();

  const onAddLiquidity = async (params) => {
    const addLiquidityParams = { ...data, ...params };
    await addLiquidity(addLiquidityParams, () => {
      setStep(2);
    });
  };

  return (
    <DashboardLayout>
      {step === 1 && (
        <AddLiquidity
          initialSupply={data?.initialSupply}
          onDeploy={onAddLiquidity}
          isDeploying={isAdding}
          setTokenImage={setTokenImage}
          setData={setData}
        />
      )}
      {step === 2 && (
        <FinalStep
          tokenImage={tokenImage}
          setStep={setStep}
          tokenAddress={data.tokenMint?.toString()}
        />
      )}
      {step === 1 && <StepByStep step={step} />}
    </DashboardLayout>
  );
};

export default CreateLiquidityPool;
